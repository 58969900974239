import React, {useState} from "react";
import {DialogContent} from "@material-ui/core";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseButton from "../CloseButton";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import withDialogManager from "./withDialogManager";
import Cookie from "js-cookie";
import {get} from "lodash";
import {HIDE_THIRD_PARTY_DIALOG} from "../../constants/cookies";
import {Button} from "@material-ui/core";

const StlDialogContent = styled(DialogContent)`
  padding: 24px 30px 8px;
  @media only screen and (max-width: 600px) {
    padding: 24px 16px 8px;
  }
`;

const StlFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 1rem;
    margin: 5px 5px 5px 0;
    text-align: left;
    white-space: nowrap;
    @media only screen and (max-width: 369px) {
      white-space: normal;
    }
  }
`;
const StlCloseButton = styled(CloseButton)`
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

const StlButtonWrapper = styled.div`
  gap: 20px;
  justify-content: center;
  @media only screen and (min-width: 600px) {
    flex-wrap: nowrap !important;
    margin-top: 25px !important;
  }
`;

function ThirdPartyLinkingDialog() {
  const dispatch = useDispatch();
  const [dialogCookie, setDialogCookie] = useState(false);
  const thirdPartyURL = useSelector((state) =>
    get(state, "dialog.options.thirdPartyURL")
  );

  const closeDialog = () => {
    dispatch({type: "DIALOG/CLOSE"});
  };

  const cancelButtonClick = () => {
    if (dialogCookie) {
      Cookie.set(HIDE_THIRD_PARTY_DIALOG, true, {expires: 30});
      window.location.reload();
    }
    closeDialog();
  };

  const routeToThirdPartySite = () => {
    if (dialogCookie) {
      Cookie.set(HIDE_THIRD_PARTY_DIALOG, true, {expires: 30});
    }

    window.open(thirdPartyURL, "_blank");
    window.location.reload();
    closeDialog();
  };

  const brandName = "Cresco Yeltrah";

  return (
    <Dialog open={true} className="relative" maxWidth="xs">
      <StlDialogContent className="mb-10">
        <div className="center-align mt-15">
          <h2 className="mb-15 mt-15 uppercase">
            You are leaving <br /> this site
          </h2>
          <p className="font-book mb-20">
            You are leaving {brandName}'s website. {brandName}'s terms of use,
            cookie management, and privacy policy notice do not apply when you
            go to this other website, so you should review those on the other
            site. {brandName} is not responsible for the content of the next or
            any other third-party sites.
          </p>

          <StlButtonWrapper className="flex-row mt-15">
            <Button
              size="medium"
              variant="contained"
              className="button-width-style nowrap"
              onClick={cancelButtonClick}
            >
              Cancel
            </Button>
            <Button
              className="button-width-style nowrap"
              size="medium"
              variant="contained"
              color="primary"
              onClick={routeToThirdPartySite}
            >
              Continue to Site
            </Button>
          </StlButtonWrapper>
          <StlFormControlLabel
            control={<Checkbox checked={dialogCookie} color="primary" />}
            label="Don't show this message again"
            onChange={(e, v) => setDialogCookie(v)}
            className="mb-15 mt-20"
          />
        </div>
      </StlDialogContent>
      <StlCloseButton
        onClick={closeDialog}
        className="absolute"
        aria-label="Close Popup"
      />
    </Dialog>
  );
}

export default withDialogManager(
  "third_party_linking_dialog",
  ThirdPartyLinkingDialog
);
