import ThirdPartyLinkingDialog from "./ThirdPartyLinkingDialog";
import CrescoYeltrahStoreLocatorDialog from "./CrescoYeltrahStoreLocatorDialog";

export const handleDisableBackdropClick = (event, reason) => {
  if (reason === "backdropClick") return false;
};

export default function Dialogs() {
  return (
    <>
      <ThirdPartyLinkingDialog />
      <CrescoYeltrahStoreLocatorDialog />
    </>
  );
}
